import React from "react";
import Img from "gatsby-image";
import { Image } from "theme-ui";

export default data => {
  return !!data.url ? (
    <Image src={data.url} />
  ) : !!data.fixed ? (
    <Img fixed={data.fixed} />
  ) : !!data.fluid ? (
    <Img fluid={data.fluid} />
  ) : null;
};
