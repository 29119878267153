import React, { useState, useEffect } from "react";

import styled from "@emotion/styled";
import { Flex, Box, Card, Button } from "theme-ui";
import { IconContext } from "react-icons";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Image from "./image";

export const Slideshow = ({
  productView = false,
  images = [],
  interval = 3000,
  width,
}) => {
  const [thumbnails, setThumnails] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [previousSlideImg, setPreviousSlideImg] = useState({});
  // const [nextSlideImg, setNextSlideImg] = useState({});
  // const [currentSlideImg, setCurrentSlideImg] = useState({});

  const [previousSlideImg, setPreviousSlideImg] = useState(null);
  const [nextSlideImg, setNextSlideImg] = useState(null);
  const [currentSlideImg, setCurrentSlideImg] = useState(null);

  useEffect(() => {
    // alert(width);

    if (images.length) {
      setThumnails(images);

      setCurrentSlideImg(images[currentSlide]);

      if (currentSlide > 0) {
        setPreviousSlideImg(images[currentSlide - 1]);
      } else {
        setPreviousSlideImg(images[images.length - 1]);
      }

      if (currentSlide === images.length - 1) {
        setNextSlideImg(images[0]);
      } else {
        setNextSlideImg(images[currentSlide + 1]);
      }

      const loop = setInterval(() => {
        if (currentSlide === images.length - 1) {
          setCurrentSlide(0);
        } else {
          setCurrentSlide(currentSlide + 1);
        }
      }, interval);

      return () => clearInterval(loop);
    }
  }, [images, currentSlide, interval]);

  function previous() {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide(thumbnails.length - 1);
    }
  }

  function next() {
    if (currentSlide === thumbnails.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  }

  const leftArrow = (
    <ArrowButton sx={{ bg: "white" }} onClick={previous}>
      {/* <StyledFontAwesomeIcon aria-hidden icon={faChevronLeft} size="1x" /> */}
      <IconContext.Provider value={{ size: "1rem" }}>
        <FaChevronLeft/>
      </IconContext.Provider>
    </ArrowButton>
  );

  const rightArrow = (
    <ArrowButton sx={{ bg: "white" }} onClick={next}>
      {/* <StyledFontAwesomeIcon aria-hidden icon={faChevronRight} size="1x" /> */}
      <IconContext.Provider value={{ size: "1rem" }}>
        <FaChevronRight/>
      </IconContext.Provider>
    </ArrowButton>
  );

  const sxBox = {
    mt: "2rem",
    mx: "auto",
  };

  const sxFlex = {
    pt: "1rem",
    pb: "1rem",
  };
  const sxOuterBox = {};

  if (!productView) {
    sxBox["boxShadow"] = "0px 10px 30px 0px rgba(0, 0, 0, 0.1)";
    sxBox["maxWidth"] = [null, "65%"];
    sxBox["minWidth"] = ["60%", null];
  } else {
    sxFlex["mx"] = "auto";
    sxOuterBox["ml"] = "2rem";
  }

  return (
    <>
      <Box sx={sxOuterBox}>
        <Box sx={sxBox}>
          {!!productView && (
            <Card sx={{ minWidth: [256, 512], mx: "auto" }}>
              <Image {...currentSlideImg} />
            </Card>
          )}

          <Flex sx={sxFlex}>
            {leftArrow}

            {images.length > 1 && width && width > 768 && (
              <Card sx={{ minWidth: [125, 256], opacity: 0.5, mx: "auto" }}>
                <Image {...previousSlideImg} sx={{ maxHeight: [256] }} />
              </Card>
            )}

            {!productView && (
              <Card sx={{ minWidth: [128, 256], mx: "auto" }}>
                <Image {...currentSlideImg} />
              </Card>
            )}

            {images.length > 2 && width && width > 1024 && (
              <Card sx={{ minWidth: [125, 256], opacity: 0.5, mx: "auto" }}>
                <Image {...nextSlideImg} />
              </Card>
            )}

            {rightArrow}
          </Flex>

          <Box sx={{ maxWidth: "50%", pb: "1rem", mx: "auto" }}>
            <Flex sx={{ justifyContent: "center" }}>
              {images.map((i, key) => (
                <StyledButton
                  current={key === currentSlide}
                  key={key}
                  type="button"
                />
              ))}
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const ArrowButton = styled(Button)`
  outline: none;

  > * {
    color: #000000;

    :active {
      cursor: pointer;
    }

    :hover {
      cursor: pointer;
    }
  }
`;

const StyledButton = styled.button<{ current: boolean }>`
  width: 10px;
  height: 12px;
  margin: 7.5px;

  border: 0;
  outline: none;

  background: black;
  border-radius: 100%;
  opacity: ${p => (p.current ? "unset" : "0.5")};

  :hover {
    cursor: pointer;
    opacity: unset;
  }
`;
